<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12">
        <mex-heading content="Locations Overview" />
      </v-col>
    </v-row>
    <mex-sperm-spinner v-if="locationOverviewLoading" spinnerText="loading clinic overview" />
    <template v-else>
      <v-row>
        <v-col cols="12">
          <location-overview-filter @filterUpdate="triggerFilterUpdate" />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12">
          <mex-card cardClass="foreground" icon="mdi-hospital-marker" title="Locations" width="100%">
            <locations-table :filter-data="filterData"/>
          </mex-card>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import locationsTable from "../../components/LicSrvComponents/LocationsTable";
import locationOverviewFilter from "../../components/LicSrvComponents/LocationOverviewFilter";

export default {
  name: 'OrganizationOverview',
  components: {
    locationsTable,
    locationOverviewFilter,
  },
  computed: {
    ...mapGetters('sysAuth', ['isLoggedIn', 'getUser']),
  },
  data() {
    return {
      locationOverviewLoading: false,
      filterData: null,
    };
  },
  methods: {
    triggerFilterUpdate(value) {
      this.filterData = value;
    },
  }
};
</script>

<style>
</style>
