<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header color="foreground">
        <v-row justify="center">
          <v-col align-self="center" cols="auto">
            <v-icon>mdi-filter</v-icon>
          </v-col>
          <v-col align-self="center" cols="auto"> Filter Locations</v-col>
        </v-row>
      </v-expansion-panel-header>
      <v-expansion-panel-content color="foreground">
        <v-row justfiy="center">
          <v-col cols="12">
            <mex-sheet color="foreground" elevation="0">
              <v-row justify="center">
                <v-col class="pr-0" cols="auto">
                  <v-autocomplete v-model="organizationNameFilter" :items="organizationNames" dense hide-details label="Organization" outlined>
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.text }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col align-self="center" class="pl-0" cols="auto">
                  <mex-btn icon="mdi-close" iconOnly @click="organizationNameFilter = ''" />
                </v-col>
                <v-col class="pr-0" cols="auto">
                  <v-autocomplete v-model="clinicNameFilter" :items="clinicNames" dense hide-details label="Clinic" outlined>
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.text }}</v-list-item-title>
                        <v-list-item-subtitle class="ma-2">{{ data.item.description }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col align-self="center" class="pl-0" cols="auto">
                  <mex-btn icon="mdi-close" iconOnly @click="clinicNameFilter = ''" />
                </v-col>
                <v-col align-self="center" class="pr-0" cols="auto">
                  <v-select v-model="systemUserNameFilter" :items="systemUserNames" dense hide-details label="System-Users" multiple outlined>
                    <template v-slot:selection="data">
                      <v-chip class="ma-1" close @click:close="removeSystemUserName(data.item)">
                        <v-icon class="mr-1" x-small>mdi-account</v-icon>
                        {{ data.item.text }}
                      </v-chip>
                    </template>
                  </v-select>
                </v-col>
                <v-col align-self="center" class="pl-0" cols="auto">
                  <mex-btn icon="mdi-close" iconOnly @click="systemUserNameFilter = null" />
                </v-col>
                <v-col class="pr-0" cols="auto">
                  <v-select v-model="activeStateIdx" :items="activeStates" dense hide-details label="State" outlined>
                    <template v-slot:selection="data">
                      <v-icon :color="data.item.iconColor" class="mr-2">{{ data.item.icon }}</v-icon>
                      {{ data.item.text }}
                    </template>
                    <template v-slot:item="data">
                      <v-icon :color="data.item.iconColor" class="mr-2">{{ data.item.icon }}</v-icon>
                      {{ data.item.text }}
                    </template>
                  </v-select>
                </v-col>
                <v-col align-self="center" class="pl-0" cols="auto">
                  <mex-btn icon="mdi-close" iconOnly @click="activeStateIdx = 0" />
                </v-col>
                <v-col class="pr-0" cols="auto">
                  <v-select v-model="selectedLocatedIn" :items="locatedInNames" dense hide-details label="Located In" outlined>
                    <template v-slot:selection="data">
                      <v-icon :color="data.item.iconColor" class="mr-2">{{ data.item.icon }}</v-icon>
                      {{ data.item.text }}
                    </template>
                    <template v-slot:item="data">
                      <v-icon :color="data.item.iconColor" class="mr-2">{{ data.item.icon }}</v-icon>
                      {{ data.item.text }}
                    </template>
                  </v-select>
                </v-col>
                <v-col align-self="center" class="pl-0" cols="auto">
                  <mex-btn icon="mdi-close" iconOnly @click="selectedLocatedIn = 0" />
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="auto">
                  <mex-btn content="Set filter" icon="mdi-filter" @click="buildFilterData" />
                </v-col>
              </v-row>
            </mex-sheet>
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import OrganizationsService from '../../services/organizations.service';
import SystemUsersService from '../../services/systemUsers.service';
import ClinicsService from "../../services/clinics.service";
import LocationsService from "../../services/locations.service";

export default {
  name: 'LicSrvLocationOverviewFilter',
  data() {
    return {
      filterData: {
        clinicName: null,
        organizationName: null,
        systemUserIDs: null,
        active: null,
        locatedIn: null,
      },
      clinicNames: [],
      clinicNameFilter: '',
      organizationNames: [],
      organizationNameFilter: '',
      systemUserNames: [],
      systemUserNameFilter: [],
      activeStates: [
        {
          text: null,
          icon: '',
        },
        {
          text: 'Active',
          icon: 'mdi-circle',
          iconColor: 'success',
        },
        {
          text: 'Inactive',
          icon: 'mdi-circle',
          iconColor: 'error',
        },
      ],
      activeStateIdx: 0,
      selectedLocatedIn: 0,
      locatedInNames: []
    };
  },
  methods: {
    removeSystemUserName(item) {
      const indexOfNameToRemove = this.systemUserNameFilter.findIndex((name) => name === item.value);
      if (indexOfNameToRemove > -1) {
        this.systemUserNameFilter.splice(indexOfNameToRemove, 1);
      }
    },
    fetchFilterData() {
      ClinicsService.getClinicNames().then((clinicNamesResponse) => {
        clinicNamesResponse.data.forEach((clinic) => {
          this.clinicNames.push({
            value: clinic.name,
            text: clinic.name,
          })
        });
        OrganizationsService.getOrganizationNames().then((organizationResponse) => {
          organizationResponse.data.forEach((orga) => {
            this.organizationNames.push({
              value: orga.name,
              text: orga.name,
            });
          });
          SystemUsersService.getSystemUserNames().then((systemUserResponse) => {
            systemUserResponse.data.forEach((systemUser) => {
              this.systemUserNames.push({
                value: systemUser.SystemUserID,
                text: systemUser.username,
              });
            });
            LocationsService.getLocationLocatedIns().then((locatedInResponse) => {
              locatedInResponse.data.locatedInResponse.forEach((locatedIn) => {
                this.locatedInNames.push({
                  value: locatedIn,
                  text: `${locatedIn.city}, ${locatedIn.name}`,
                });
              });
            });
          });
        });
      })
    },
    buildFilterData() {
      this.filterData.clinicName = this.clinicNameFilter;
      this.filterData.organizationName = this.organizationNameFilter;
      this.filterData.systemUserIDs = this.systemUserNameFilter;
      this.filterData.active = this.activeStateIdx ? Boolean(this.activeStateIdx === this.activeStates[1].text) : undefined;
      this.filterData.locatedIn = this.selectedLocatedIn;
      this.$emit('filterUpdate', this.filterData);
    },
  },
  watch: {
    async clinicNameFilter(selectedClinicName) {
      this.organizationNames = (await OrganizationsService.getOrganizationToClinicName(selectedClinicName)).data;
      for (let i = 0; i < this.organizationNames.length; i++) {
        this.organizationNames[i] = { text: this.organizationNames[i], value: this.organizationNames[i] };
      }
      this.locatedInNames = (await LocationsService
        .getLocationLocatedIns(selectedClinicName))
        .data.locatedInResponse;
      for (let i = 0; i < this.locatedInNames.length; i++) {
        this.locatedInNames[i] = { text: `${this.locatedInNames[i].city}, ${this.locatedInNames[i].name}`, value: this.locatedInNames[i] };
      }
    },
    async organizationNameFilter(value) {
      this.clinicNames = (await ClinicsService.getClinicsToOrganizationName(value)).data;
      for (let i = 0; i < this.clinicNames.length; i++) {
        this.clinicNames[i] = { text: this.clinicNames[i], value: this.clinicNames[i] };
      }
      this.locatedInNames = (await LocationsService
        .getLocationLocatedIns(null, value))
        .data.locatedInResponse;
      for (let i = 0; i < this.locatedInNames.length; i++) {
        this.locatedInNames[i] = { text: `${this.locatedInNames[i].city}, ${this.locatedInNames[i].name}`, value: this.locatedInNames[i] };
      }
    }
  },
  created() {
    this.fetchFilterData();
  },
};
</script>

<style></style>
