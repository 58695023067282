<template>
  <expansion-sheet :elevation="locationOverviewElevation" color="foreground" rounded :show-arrow="false">
    <template v-slot:header>
      <mex-p :content="location.name" fontSize="h6" fontWeight="bold-italic" />
    </template>
    <template v-slot:content>
      <v-row justify="center">
        <template v-if="!hideLocationGoTo || !hideClinicGoTo || !hideOrgaGoTo">
          <v-col cols="2">
            <v-row v-if="!hideLocationGoTo" justify="start">
              <v-col cols="auto">
                <v-tooltip left>
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn small v-bind="attrs" v-on="on" @click="goToLocationView">
                      <v-icon small class="mr-1">mdi-arrow-right-bold-hexagon-outline</v-icon>
                      Location
                    </v-btn>
                  </template>
                  <span>Go to location view</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row v-if="!hideClinicGoTo" justify="start">
              <v-col cols="auto">
                <v-tooltip left>
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn small v-bind="attrs" v-on="on" @click="goToClinicView">
                      <v-icon small class="mr-1">mdi-arrow-right-bold-hexagon-outline</v-icon>
                      Clinic
                    </v-btn>
                  </template>
                  <span>Go to clinic view</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row v-if="!hideOrgaGoTo" justify="start">
              <v-col cols="auto">
                <v-tooltip left>
                  <template v-slot:activator="{ attrs, on }">
                    <v-btn small v-bind="attrs" v-on="on" @click="goToOrganizationView">
                      <v-icon small class="mr-1">mdi-arrow-right-bold-hexagon-outline</v-icon>
                      Organization
                    </v-btn>
                  </template>
                  <span>Go to organization view</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>
        </template>
        <v-col cols="10">
          <mex-sheet color="dark" rounded :elevation="locationOverviewElevation">
            <v-row>
              <v-col>
                <h3>Location-ID</h3>
                <mex-p :content="$props.location.LocationID"></mex-p>
              </v-col>
              <v-col>
                <h3>Location-UUID</h3>
                <mex-p :content="$props.location.locationUUID"></mex-p>
              </v-col>
              <v-col>
                <h3>Installation-Date</h3>
                <mex-p :content="$dateFormatter.convertJsonDate($props.location.installationDate).date"></mex-p>
              </v-col>
              <v-col>
                <h3>Forced Deactivation-Date</h3>
                <mex-p :content="$dateFormatter.convertJsonDate($props.location.forcedDeactivationDate).date"></mex-p>
              </v-col>
              <v-col>
                <h3>Deactivation-Date</h3>
                <mex-p :content="$dateFormatter.convertJsonDate($props.location.deactivationDate).date"></mex-p>
              </v-col>
            </v-row>
          </mex-sheet>
        </v-col>
      </v-row>
    </template>
  </expansion-sheet>
</template>

<script>
import { mapGetters } from 'vuex';
import ExpansionSheet from './ExpansionSheet.vue';
import MexSheet from "../MedITEX_Vue_Components/MexComponents/MexSheet";

export default {
  components: { MexSheet, ExpansionSheet },
  name: 'LocationOverview',
  props: {
    location: {
      default: null,
    },
    clinicID: {
      type: String,
      default: null,
    },
    organizationID: {
      type: Number,
      default: null,
    },
    hideLocationGoTo: {
      type: Boolean,
      default: false,
    },
    hideClinicGoTo: {
      type: Boolean,
      default: false,
    },
    hideOrgaGoTo: {
      type: Boolean,
      default: false,
    },
    locationOverviewElevation: {
      type: String,
      default: '6',
    },
  },
  computed: {
    ...mapGetters('sysAuth', ['getUserPermissions']),
  },
  data() {
    return {
    };
  },
  methods: {
    goToLocationView() {
      this.$router.push({ name: 'LocationView', params: { id: this.$props.location.LocationID } });
    },
    goToClinicView() {
      this.$router.push({ name: 'ClinicView', params: { id: this.clinicID } });
    },
    goToOrganizationView() {
      this.$router.push({ name: 'OrganizationView', params: { id: this.organizationID } });
    },
  },
};
</script>

<style></style>
