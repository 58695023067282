<template>
  <mex-data-table
    dense
    :data="locationTableData"
    :enableServerSidePagination="true"
    :headers="getDynamicTableHeaders"
    :hide-default-footer="true"
    :isLoading="locationTableLoading"
    loadingText="Loading location data..."
    :srvSideActPage="actPage"
    :srvSideActSize="actSize"
    :srvSideSizeItems="getTablePagination.rowsPerPageWithNames"
    :srvSideTotalPages="totalPages"
    :srvSideTotalItems="totalItems"
    item-key="LocationID"
    show-expand
    single-expand
    tableClass="foreground"
    @pageChanged="handlePageChange"
    @sizeChanged="handleSizeChange"
  >
    <template v-slot:top>
      <v-row justify="end">
        <v-col cols="auto">
          <mex-btn icon="mdi-refresh" iconOnly tooltip="refresh the clinic overview" tooltipPosition="top" @click="fetchLocationOverviewData" />
        </v-col>
      </v-row>
    </template>
    <template v-slot:item.name="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn style="font-size: smaller; height: fit-content" class="pa-1 text-truncate" color="foreground" small @click="goToLocationView(item.LocationID)" v-on="on" v-bind="attrs">
            <span style="max-width: 300px">{{ item.name }}</span>
          </v-btn>
        </template>
        <span>{{ item.name }}</span>
      </v-tooltip>
    </template>
    <template v-slot:item.Clinic="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn style="font-size: smaller; height: fit-content; max-width: 300px" class="pa-1" color="foreground" small @click="goToClinicView(item.Clinic.ClinicID)" v-on="on" v-bind="attrs">
            <span style="max-width: 300px">{{ item.Clinic.name }}</span>
          </v-btn>
        </template>
        <span>{{ item.Clinic.name }}</span>
      </v-tooltip>
    </template>
    <template v-slot:item.Organization="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn style="font-size: smaller; height: fit-content" class="pa-1" color="foreground" small @click="goToOrganizationView(item.Clinic.Organization.OrganizationID)" v-on="on" v-bind="attrs">
            <span style="max-width: 250px">{{ item.Clinic.Organization.name }}</span>
          </v-btn>
        </template>
        <span>{{ item.Clinic.Organization.name }}</span>
      </v-tooltip>
    </template>
    <template v-slot:item.Addresses="{ item }">
      <mex-p style="max-width: 200px" :content="String(item.Addresses[0] && item.Addresses[0].city ? item.Addresses[0].city : '-') + ', ' + String(item.Addresses[0] && item.Addresses[0].Country && item.Addresses[0].Country.name ? item.Addresses[0].Country.name : '-')"></mex-p>
    </template>
    <template v-slot:item.state="{ item }">
      <div>
        <v-tooltip v-if="locationIsActive(item.installationDate, item.forcedDeactivationDate ? item.forcedDeactivationDate : item.deactivationDate)" color="success" right>
          <template v-slot:activator="{ on, attrs }">
            <mex-status-lamp v-bind="attrs" v-on="on" color="success"/>
          </template>
          <span> Location is active </span>
        </v-tooltip>
        <v-tooltip v-else color="error" right>
          <template v-slot:activator="{ on, attrs }">
            <mex-status-lamp v-bind="attrs" v-on="on" color="error" />
          </template>
          <span> Location is deactivated </span>
        </v-tooltip>
      </div>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" class="extendedLocationRow">
        <locations-detailed-view
          :location="item"
          :clinicID="item.ClinicID"
          :hideHeader="true"
          :locationOverviewElevation="'0'"
          :organizationID="item.Clinic.OrganizationID"
          class="ma-3"
        />
      </td>
    </template>
  </mex-data-table>
</template>

<script>
import { mapGetters } from 'vuex';
import LocationsService from '../../services/locations.service';
import tablePagination from '../../config/tablePagination.config';
import LocationsDetailedView from '../LicSrvComponents/LocationDetailedView';

export default {
  name: 'LocationsTable',
  props: {
    excludeHeaders: {
      type: Array,
      default: () => {
        return [];
      },
      description: '',
    },
    hideLocationGoTo: {
      type: Boolean,
      default: false,
      description: '',
    },
    hideClinicsGoTo: {
      type: Boolean,
      default: false,
      description: '',
    },
    hideOrgaGoTo: {
      type: Boolean,
      default: false,
      description: '',
    },
    filterData: {
      type: Object,
      default: () => {
        return {
          locationName: null,
          organizationName: null,
          registerType: null,
          state: null,
          deactivation: null,
          systemUserIDs: null,
        };
      },
      description: '',
    },
  },
  components: {
    LocationsDetailedView,
  },
  computed: {
    ...mapGetters('sysAuth', ['getUserPermissions']),
    getDynamicTableHeaders() {
      return this.locationTableHeaders.filter((x) => !this.excludeHeaders.includes(x.value));
    },
    readOrganization() {
      return this.getUserPermissions.includes('read_Customer Administration_Organization Administration');
    },
    getTablePagination() {
      return tablePagination;
    }
  },
  data() {
    return {
      // visualization
      locationTableLoading: false,
      // location table
      actPage: 1,
      actSize: tablePagination.defaultRowsPerPage,
      totalItems: 0,
      totalPages: 0,
      locationTableData: [],
      locationTableHeaders: [
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Clinic',
          align: 'start',
          sortable: true,
          value: 'Clinic',
        },
        {
          text: 'Organization',
          align: 'start',
          sortable: true,
          value: 'Organization',
        },
        {
          text: 'Located in (City, Country)',
          align: 'start',
          sortable: true,
          value: 'Addresses',
          sort: (a, b) => {
            const extractLocation = (addresses) => {
              const address = addresses && addresses[0];
              const city = address && address.city ? address.city.toLowerCase() : '';
              const country = address && address.Country && address.Country.name ? address.Country.name.toLowerCase() : '';
              return { city, country };
            };

            const locA = extractLocation(a);
            const locB = extractLocation(b);

            // compare by city first
            const cityDiff = locA.city.localeCompare(locB.city);
            if (cityDiff !== 0) {
              return cityDiff;
            }

            // if cities are same, then compare by country
            return locA.country.localeCompare(locB.country);
          }
        },
        {
          text: 'State',
          align: 'start',
          sortable: false,
          value: 'state',
        },
      ],
    };
  },
  watch: {
    filterData: {
      handler() {
        this.fetchLocationOverviewData();
      },
      deep: true,
    },
  },
  methods: {
    locationIsActive(installationDate, deactivationDate) {
      const now = new Date();
      return new Date(installationDate) <= now && now <= new Date(deactivationDate);
    },
    fetchLocationOverviewData() {
      this.locationTableLoading = true;
      LocationsService.getLocationsOverview(this.actPage, this.actSize, this.filterData)
        .then((response) => {
          this.locationTableData = response.data.data;
          this.totalItems = response.data.totalItems;
          this.totalPages = response.data.totalPages;
          this.locationTableLoading = false;
        })
        .catch((err) => {
          this.$toast.error(err.response.data);
          this.locationTableLoading = false;
        });
    },
    handlePageChange(value) {
      this.actPage = value;
      this.fetchLocationOverviewData();
    },
    handleSizeChange(value) {
      this.actPage = 1;
      this.actSize = value;
      this.fetchLocationOverviewData();
    },
    goToOrganizationView(id) {
      this.$router.push({ name: 'OrganizationView', params: { id } });
    },
    goToLocationView(id) {
      this.$router.push({ name: 'LocationView', params: { id } });
    },
    goToClinicView(id) {
      this.$router.push({ name: 'ClinicView', params: { id } });
    },
  },
  mounted() {
    this.fetchLocationOverviewData();
  },
};
</script>

<style>
.extendedLocationRow {
  box-shadow: inset 0 0 1em var(--v-primary-base);
}
</style>
