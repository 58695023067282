var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('expansion-sheet',{attrs:{"elevation":_vm.locationOverviewElevation,"color":"foreground","rounded":"","show-arrow":false},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('mex-p',{attrs:{"content":_vm.location.name,"fontSize":"h6","fontWeight":"bold-italic"}})]},proxy:true},{key:"content",fn:function(){return [_c('v-row',{attrs:{"justify":"center"}},[(!_vm.hideLocationGoTo || !_vm.hideClinicGoTo || !_vm.hideOrgaGoTo)?[_c('v-col',{attrs:{"cols":"2"}},[(!_vm.hideLocationGoTo)?_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.goToLocationView}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-arrow-right-bold-hexagon-outline")]),_vm._v(" Location ")],1)]}}],null,false,973118608)},[_c('span',[_vm._v("Go to location view")])])],1)],1):_vm._e(),(!_vm.hideClinicGoTo)?_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.goToClinicView}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-arrow-right-bold-hexagon-outline")]),_vm._v(" Clinic ")],1)]}}],null,false,77132176)},[_c('span',[_vm._v("Go to clinic view")])])],1)],1):_vm._e(),(!_vm.hideOrgaGoTo)?_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":""},on:{"click":_vm.goToOrganizationView}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-arrow-right-bold-hexagon-outline")]),_vm._v(" Organization ")],1)]}}],null,false,3168522832)},[_c('span',[_vm._v("Go to organization view")])])],1)],1):_vm._e()],1)]:_vm._e(),_c('v-col',{attrs:{"cols":"10"}},[_c('mex-sheet',{attrs:{"color":"dark","rounded":"","elevation":_vm.locationOverviewElevation}},[_c('v-row',[_c('v-col',[_c('h3',[_vm._v("Location-ID")]),_c('mex-p',{attrs:{"content":_vm.$props.location.LocationID}})],1),_c('v-col',[_c('h3',[_vm._v("Location-UUID")]),_c('mex-p',{attrs:{"content":_vm.$props.location.locationUUID}})],1),_c('v-col',[_c('h3',[_vm._v("Installation-Date")]),_c('mex-p',{attrs:{"content":_vm.$dateFormatter.convertJsonDate(_vm.$props.location.installationDate).date}})],1),_c('v-col',[_c('h3',[_vm._v("Forced Deactivation-Date")]),_c('mex-p',{attrs:{"content":_vm.$dateFormatter.convertJsonDate(_vm.$props.location.forcedDeactivationDate).date}})],1),_c('v-col',[_c('h3',[_vm._v("Deactivation-Date")]),_c('mex-p',{attrs:{"content":_vm.$dateFormatter.convertJsonDate(_vm.$props.location.deactivationDate).date}})],1)],1)],1)],1)],2)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }