var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mex-data-table',{attrs:{"dense":"","data":_vm.locationTableData,"enableServerSidePagination":true,"headers":_vm.getDynamicTableHeaders,"hide-default-footer":true,"isLoading":_vm.locationTableLoading,"loadingText":"Loading location data...","srvSideActPage":_vm.actPage,"srvSideActSize":_vm.actSize,"srvSideSizeItems":_vm.getTablePagination.rowsPerPageWithNames,"srvSideTotalPages":_vm.totalPages,"srvSideTotalItems":_vm.totalItems,"item-key":"LocationID","show-expand":"","single-expand":"","tableClass":"foreground"},on:{"pageChanged":_vm.handlePageChange,"sizeChanged":_vm.handleSizeChange},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('mex-btn',{attrs:{"icon":"mdi-refresh","iconOnly":"","tooltip":"refresh the clinic overview","tooltipPosition":"top"},on:{"click":_vm.fetchLocationOverviewData}})],1)],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-1 text-truncate",staticStyle:{"font-size":"smaller","height":"fit-content"},attrs:{"color":"foreground","small":""},on:{"click":function($event){return _vm.goToLocationView(item.LocationID)}}},'v-btn',attrs,false),on),[_c('span',{staticStyle:{"max-width":"300px"}},[_vm._v(_vm._s(item.name))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])])]}},{key:"item.Clinic",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-1",staticStyle:{"font-size":"smaller","height":"fit-content","max-width":"300px"},attrs:{"color":"foreground","small":""},on:{"click":function($event){return _vm.goToClinicView(item.Clinic.ClinicID)}}},'v-btn',attrs,false),on),[_c('span',{staticStyle:{"max-width":"300px"}},[_vm._v(_vm._s(item.Clinic.name))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.Clinic.name))])])]}},{key:"item.Organization",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pa-1",staticStyle:{"font-size":"smaller","height":"fit-content"},attrs:{"color":"foreground","small":""},on:{"click":function($event){return _vm.goToOrganizationView(item.Clinic.Organization.OrganizationID)}}},'v-btn',attrs,false),on),[_c('span',{staticStyle:{"max-width":"250px"}},[_vm._v(_vm._s(item.Clinic.Organization.name))])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.Clinic.Organization.name))])])]}},{key:"item.Addresses",fn:function(ref){
var item = ref.item;
return [_c('mex-p',{staticStyle:{"max-width":"200px"},attrs:{"content":String(item.Addresses[0] && item.Addresses[0].city ? item.Addresses[0].city : '-') + ', ' + String(item.Addresses[0] && item.Addresses[0].Country && item.Addresses[0].Country.name ? item.Addresses[0].Country.name : '-')}})]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('div',[(_vm.locationIsActive(item.installationDate, item.forcedDeactivationDate ? item.forcedDeactivationDate : item.deactivationDate))?_c('v-tooltip',{attrs:{"color":"success","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('mex-status-lamp',_vm._g(_vm._b({attrs:{"color":"success"}},'mex-status-lamp',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(" Location is active ")])]):_c('v-tooltip',{attrs:{"color":"error","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('mex-status-lamp',_vm._g(_vm._b({attrs:{"color":"error"}},'mex-status-lamp',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(" Location is deactivated ")])])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"extendedLocationRow",attrs:{"colspan":headers.length}},[_c('locations-detailed-view',{staticClass:"ma-3",attrs:{"location":item,"clinicID":item.ClinicID,"hideHeader":true,"locationOverviewElevation":'0',"organizationID":item.Clinic.OrganizationID}})],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }